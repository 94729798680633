/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import IconDictionary from '../../../../../../components/Icons/icons';
import { Button, Card, Spinner } from '../../../../../../components';
import { ToastNotify } from '../../../../../../components/Toast/toast';
import { ApiCompany } from '../../../api';
import {
  dictionaryError,
  fileToBase64,
} from '../../../../../../helpers/utils.helper';
import { ApiClient } from '../../../../../clients/api';

interface importClientsProps {
  id: number;
  mode: 'admin' | 'companieUser';
  reloadTable?: (e: string) => void;
}

interface resultImportProps {
  totalCustomersWorksheet: number;
  totalNewCustomers: number;
  totalCustomersUpdated: number;
  totalTotalizedCustomers: number;
  errors: { line: number; column: string; error: string }[];
}

const ImportClients: React.FC<importClientsProps> = ({
  id,
  mode,
  reloadTable = () => {},
}) => {
  const [resultsImport, setResultsImport] = useState<resultImportProps | null>(
    null
  );
  const [files, setFiles] = useState<any>();
  const { notify } = ToastNotify();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [showPopUpConfirm, setShowPopUpConfirm] = useState(false);

  const createFile = async () => {
    setIsLoadingSubmit(true);
    setShowPopUpConfirm(false);
    const fileForm = files;

    try {
      const payload: any = {
        title: fileForm[0].name,
        file: {
          originalFileName: fileForm[0].name,
          base64: (await fileToBase64(fileForm[0])).split(',')[1],
        },
      };
      const res =
        mode === 'admin'
          ? await ApiCompany.createImport(payload, id)
          : await ApiClient.createImport(payload, id);
      if (res && res.status === 'success') {
        setResultsImport(res.data);
        notify({ message: 'Planilha importada com sucesso.', type: 'Success' });
        reloadTable(String(Math.random()));
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
      }
    } catch (error) {
      notify({ message: 'Erro ao realizar a importação.', type: 'Error' });
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileForm = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setFiles(fileForm);
      setShowPopUpConfirm(true);
    },
  });

  return (
    <>
      {showPopUpConfirm && (
        <Transition appear show={showPopUpConfirm} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40"
            onClose={() => setShowPopUpConfirm(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-md sm:text-lg font-medium leading-6 text-gray-900 flex items-center gap-3"
                    >
                      Deseja realmente iniciar a importação desta planilha?
                    </Dialog.Title>

                    <div className="mt-6 flex w-full justify-center gap-3">
                      <Button
                        variant="outline-primary"
                        className="w-36"
                        onClick={() => setShowPopUpConfirm(false)}
                      >
                        Não
                      </Button>
                      <Button
                        variant="primary-strong"
                        className="w-36"
                        onClick={createFile}
                        isLoading={isLoadingSubmit}
                      >
                        Sim
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}

      <div className="w-full h-fit">
        {' '}
        <Card
          className={`w-full ${
            mode === 'admin' ? 'p-7 mt-2' : 'p-0'
          }  mb-3 flex-col min-h-[50vh] 2xl:min-h-[60vh]`}
        >
          {' '}
          {mode === 'admin' && (
            <p className="text-xs sm:text-sm">
              <strong>
                Importação de clientes {mode === 'admin' && 'e totalizadores'}
              </strong>
            </p>
          )}
          {isLoadingSubmit ? (
            <div className="w-full h-[40vh] flex items-center justify-center">
              {' '}
              <Spinner />
              <p>Processando importação, por favor aguarde...</p>
            </div>
          ) : (
            <>
              <div
                className={`flex gap-3 items-center ${
                  mode === 'admin' ? 'mt-5' : 'mt-0'
                }`}
              >
                <p className="text-xs">
                  Para isto, faça download da planilha padrão e informe os dados
                </p>
                {mode === 'admin' ? (
                  <IconDictionary name="Excel" color="#00A374" size={24} />
                ) : (
                  <Link
                    to="/files/planilha_modelo_clientes.xlsx"
                    target="_blank"
                  >
                    <IconDictionary name="Excel" color="#00A374" size={24} />
                  </Link>
                )}
              </div>
              <div className="flex flex-col items-center w-full mt-7">
                <div
                  {...getRootProps()}
                  className={classNames(
                    'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background mb-4 relative w-full h-24 flex items-center justify-center',
                    {
                      'border-[#DC7E19] bg-primary-400/10': isDragActive,
                    }
                  )}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="font-light text-sm text-primary">
                      Solte o arquivo aqui...
                    </p>
                  ) : (
                    <p className="font-light text-sm text-center text-[#555]">
                      Arraste e solte aqui ou clique para selecionar um arquivo
                    </p>
                  )}
                </div>
              </div>
              {resultsImport !== null && (
                <>
                  <div className="text-sm">
                    <strong>Resultado da importação:</strong>
                    <p className="ml-4">
                      • Total de clientes na planilha:{' '}
                      {resultsImport.totalCustomersWorksheet}
                    </p>
                    <p className="ml-4 text-green">
                      • Total de clientes novos:{' '}
                      {resultsImport.totalNewCustomers}
                    </p>
                    <p className="ml-4 text-blue">
                      • Total de clientes atualizados:{' '}
                      {resultsImport.totalCustomersUpdated}
                    </p>
                    {mode === 'admin' && (
                      <p className="ml-4 text-blue">
                        • Total de clientes totalizados:{' '}
                        {resultsImport.totalTotalizedCustomers}
                      </p>
                    )}
                    {mode === 'companieUser' && (
                      <p className="ml-4 text-red">
                        • Total de erros: {resultsImport.errors.length}
                      </p>
                    )}
                  </div>
                  {mode === 'companieUser' && (
                    <div className="max-w-full overflow-x-auto">
                      <div className="min-w-[400px] grid grid-cols-5 text-sm mt-7 max-h-[250px] overflow-y-auto">
                        <strong className="pl-2 pb-1 border-b border-r border-solid border-gray-300">
                          Linha
                        </strong>
                        <strong className="pl-2 pb-1 border-b border-r border-solid border-gray-300">
                          Coluna
                        </strong>
                        <strong className="pl-2 pb-1 col-span-3 border-b border-solid border-gray-300">
                          Erro
                        </strong>
                        {resultsImport.errors.map((error, index) => (
                          <>
                            <p
                              className={classNames([
                                'pl-2 py-1 text-xs',
                                resultsImport.errors.length - 1 === index
                                  ? 'border-r border-l-0 border-solid border-gray-300'
                                  : 'border-b border-r border-solid border-gray-300',
                              ])}
                            >
                              {error.line}
                            </p>
                            <p
                              className={classNames([
                                'pl-2 py-1 text-xs',
                                resultsImport.errors.length - 1 === index
                                  ? 'border-r border-l-0 border-solid border-gray-300'
                                  : 'border-b border-r border-solid border-gray-300',
                              ])}
                            >
                              {error.column}
                            </p>
                            <p
                              className={classNames([
                                'pl-2 py-1 text-xs col-span-3',
                                resultsImport.errors.length - 1 === index
                                  ? 'border-l-0 border-solid border-gray-300'
                                  : 'border-b border-solid border-gray-300',
                              ])}
                            >
                              {error.error}
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default ImportClients;
