/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Input, SelectComponent } from '../../../components';
import { ApiExpenses } from '../api';
import { getAddressByCEP } from '../../../helpers/address.helper';
import { ToastNotify } from '../../../components/Toast/toast';
import ServiceInvoiceAccessData from './ServiceInvoiceAccessData';
import CompanyInvoiceServices from './CompanyInvoiceServices';

interface FormProps {
  onSubmit: (data: any) => void;
  defaultValues: Record<string, any>;
  onBack?: () => void;
}

const TAX_REGIME_OPTIONS = [
  { label: 'Isento', value: 'Isento' },
  {
    label: 'Normal',
    value: 'Normal',
  },
  { label: 'Simples Nacional', value: 'SimplesNacional' },
];

const SPECIAL_TAX_REGIME_OPTIONS = [
  { label: 'Automático', value: 'Automatico' },
  { label: 'Nenhum', value: 'Nenhum' },
  { label: 'Microempresa Municipal', value: 'MicroempresaMunicipal' },
  { label: 'Estimativa', value: 'Estimativa' },
  { label: 'Sociedade de Profissionais', value: 'SociedadeDeProfissionais' },
  { label: 'Cooperativa', value: 'Cooperativa' },
  {
    label: 'Microempreendedor Individual',
    value: 'MicroempreendedorIndividual',
  },
  {
    label: 'Microempresário Empresa de Pequeno Porte',
    value: 'MicroempresarioEmpresaPequenoPorte',
  },
];

const LEGAL_NATURE_OPTIONS = [
  { label: 'Empresa Pública', value: 'EmpresaPublica' },
  { label: 'Sociedade de Economia Mista', value: 'SociedadeEconomiaMista' },
  { label: 'Sociedade Anônima Aberta', value: 'SociedadeAnonimaAberta' },
  { label: 'Sociedade Anônima Fechada', value: 'SociedadeAnonimaFechada' },
  {
    label: 'Sociedade Empresária Limitada',
    value: 'SociedadeEmpresariaLimitada',
  },
  {
    label: 'Sociedade Empresária em Nome Coletivo',
    value: 'SociedadeEmpresariaEmNomeColetivo',
  },
  {
    label: 'Sociedade Empresária em Comandita Simples',
    value: 'SociedadeEmpresariaEmComanditaSimples',
  },
  {
    label: 'Sociedade Empresária em Comandita por Ações',
    value: 'SociedadeEmpresariaEmComanditaporAcoes',
  },
  {
    label: 'Sociedade em Conta de Participação',
    value: 'SociedadeemContaParticipacao',
  },
  { label: 'Empresário', value: 'Empresario' },
  { label: 'Cooperativa', value: 'Cooperativa' },
  { label: 'Consórcio de Sociedades', value: 'ConsorcioSociedades' },
  { label: 'Grupo de Sociedades', value: 'GrupoSociedades' },
  {
    label: 'Empresa Domiciliada no Exterior',
    value: 'EmpresaDomiciliadaExterior',
  },
  { label: 'Clube/Fundo de Investimento', value: 'ClubeFundoInvestimento' },
  { label: 'Sociedade Simples Pura', value: 'SociedadeSimplesPura' },
  { label: 'Sociedade Simples Limitada', value: 'SociedadeSimplesLimitada' },
  {
    label: 'Sociedade Simples em Nome Coletivo',
    value: 'SociedadeSimplesEmNomeColetivo',
  },
  {
    label: 'Sociedade Simples em Comandita Simples',
    value: 'SociedadeSimplesEmComanditaSimples',
  },
  { label: 'Empresa Binacional', value: 'EmpresaBinacional' },
  { label: 'Consórcio de Empregadores', value: 'ConsorcioEmpregadores' },
  { label: 'Consórcio Simples', value: 'ConsorcioSimples' },
  { label: 'Eireli Natureza Empresária', value: 'EireliNaturezaEmpresaria' },
  { label: 'Eireli Natureza Simples', value: 'EireliNaturezaSimples' },
  { label: 'Serviço Notarial', value: 'ServicoNotarial' },
  { label: 'Fundação Privada', value: 'FundacaoPrivada' },
  { label: 'Serviço Social Autônomo', value: 'ServicoSocialAutonomo' },
  { label: 'Condomínio Edilício', value: 'CondominioEdilicio' },
  {
    label: 'Comissão de Conciliação Prévia',
    value: 'ComissaoConciliacaoPrevia',
  },
  {
    label: 'Entidade de Mediação e Arbitragem',
    value: 'EntidadeMediacaoArbitragem',
  },
  { label: 'Partido Político', value: 'PartidoPolitico' },
  { label: 'Entidade Sindical', value: 'EntidadeSindical' },
  {
    label: 'Estabelecimento no Brasil de Fundação ou Associação Estrangeira',
    value: 'EstabelecimentoBrasilFundacaoAssociacaoEstrangeiras',
  },
  {
    label: 'Fundação/Associação Domiciliada no Exterior',
    value: 'FundacaoAssociacaoDomiciliadaExterior',
  },
  { label: 'Organização Religiosa', value: 'OrganizacaoReligiosa' },
  { label: 'Comunidade Indígena', value: 'ComunidadeIndigena' },
  { label: 'Fundo Privado', value: 'FundoPrivado' },
  { label: 'Associação Privada', value: 'AssociacaoPrivada' },
];

const schema = Yup.object().shape({
  name: Yup.string().required('O campo Razão social é obrigatório.'),
  tradeName: Yup.string().optional(),
  federalTaxNumber: Yup.string().required('O campo CNPJ é obrigatório.'), // CNPJ validation requires a custom function
  email: Yup.string()
    .email('E-mail inválido.')
    .nullable()
    .required('O campo E-mail é obrigatório.'),
  openningDate: Yup.date().required('O campo Data de Abertura é obrigatório.'),
  // taxRegime: Yup.string()
  //   .oneOf(
  //     ['Isento', 'SimplesNacional', 'Normal'],
  //     'Regime de Tributação inválido.'
  //   )
  //   .optional(),
  legalNature: Yup.string()
    .oneOf(
      [
        'EmpresaPublica',
        'SociedadeEconomiaMista',
        'SociedadeAnonimaAberta',
        'SociedadeAnonimaFechada',
        'SociedadeEmpresariaLimitada',
        'SociedadeEmpresariaEmNomeColetivo',
        'SociedadeEmpresariaEmComanditaSimples',
        'SociedadeEmpresariaEmComanditaporAcoes',
        'SociedadeemContaParticipacao',
        'Empresario',
        'Cooperativa',
        'ConsorcioSociedades',
        'GrupoSociedades',
        'EmpresaDomiciliadaExterior',
        'ClubeFundoInvestimento',
        'SociedadeSimplesPura',
        'SociedadeSimplesLimitada',
        'SociedadeSimplesEmNomeColetivo',
        'SociedadeSimplesEmComanditaSimples',
        'EmpresaBinacional',
        'ConsorcioEmpregadores',
        'ConsorcioSimples',
        'EireliNaturezaEmpresaria',
        'EireliNaturezaSimples',
        'ServicoNotarial',
        'FundacaoPrivada',
        'ServicoSocialAutonomo',
        'CondominioEdilicio',
        'ComissaoConciliacaoPrevia',
        'EntidadeMediacaoArbitragem',
        'PartidoPolitico',
        'EntidadeSindical',
        'EstabelecimentoBrasilFundacaoAssociacaoEstrangeiras',
        'FundacaoAssociacaoDomiciliadaExterior',
        'OrganizacaoReligiosa',
        'ComunidadeIndigena',
        'FundoPrivado',
        'AssociacaoPrivada',
      ],
      'Natureza Jurídica inválida.'
    )
    .optional(),
  companyRegistryNumber: Yup.number()
    .integer(
      'O Número de Inscricação na Junta Comercial deve ser um número inteiro.'
    )
    .optional(),
  issRate: Yup.number()
    .optional()
    .typeError('A Alíquota do ISS deve ser um número.'),
  // fiscalStatus: Yup.string()
  //   .oneOf(
  //     ['CityNotSupported', 'Pending', 'Inactive', 'None', 'Active'],
  //     'Status Fiscal inválido.'
  //   )
  //   .optional(),
  createdOn: Yup.date().optional(),
  modifiedOn: Yup.date().optional(),
  postalCode: Yup.string().optional(),
  street: Yup.string().required(), // required
  number: Yup.string().required(), // required
  additionalInformation: Yup.string().optional(),
  district: Yup.string().optional(),
  city: Yup.string(),
  state: Yup.string().optional(),
});

const fiscalFormSchema = Yup.object().shape({
  regionalTaxNumber: Yup.number()
    .integer(
      'O Número de Inscricação na SEFAZ (IE) deve ser um número inteiro.'
    )
    .optional(),
  municipalTaxNumber: Yup.string().required(
    'O campo Número de Registro Tributário Municipal é obrigatório.'
  ),
  specialTaxRegime: Yup.string()
    .oneOf(
      SPECIAL_TAX_REGIME_OPTIONS.map((o) => o.value),
      'Regime Especial de Tributação inválido.'
    )
    .optional(),
});

const serviceInvoiceSchema = Yup.object().shape({
  rpsSerialNumber: Yup.string().optional(),
  rpsNumber: Yup.number()
    .integer('O Número do RPS deve ser um número inteiro.')
    .default(null)
    .optional(),
});

const ServiceInvoiceForm: FC<FormProps> = ({
  onSubmit,
  onBack,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(serviceInvoiceSchema),
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2>Dados fiscais</h2>
      </div>
      <div className="grid grid-cols-2 gap-6 items-end">
        <div>
          <Input
            variant="outline-orange"
            {...register('rpsSerialNumber')}
            title="Série do RPS"
            errorMensage={errors.rpsSerialNumber?.message as string}
          />
        </div>

        <div>
          <Input
            variant="outline-orange"
            {...register('rpsNumber')}
            title="Número do RPS"
            errorMensage={errors.rpsNumber?.message as string}
          />
        </div>
      </div>
      <div className="flex justify-center gap-2">
        <Button className="px-4" onClick={onBack}>
          Voltar
        </Button>
        <Button type="submit" className="px-4">
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

const CompanyDataForm: FC<FormProps> = ({ onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onChangePostalCode = async (event: any) => {
    if (event.target.value?.length !== 8) {
      return;
    }
    const addressByCEP = await getAddressByCEP(event.target.value);
    if (addressByCEP.erro) {
      setError('postalCode', {
        message: 'CEP inválido',
        type: 'pattern',
      });
    }
    setValue('street', addressByCEP.logradouro);
    setValue('number', addressByCEP.number);
    setValue('additionalInformation', addressByCEP.complemento);
    setValue('district', addressByCEP.bairro);
    setValue('state', addressByCEP.uf);
    setValue('city', addressByCEP.localidade);
    setValue('code', addressByCEP.ibge);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div>
        <div className="grid grid-cols-2 gap-6 items-end">
          <div>
            <Input
              variant="outline-orange"
              {...register('federalTaxNumber')}
              title="CNPJ"
              errorMensage={errors.federalTaxNumber?.message as string}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Código da Natureza Jurídica
            </label>
            <Controller
              name="legalNature"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  {...field}
                  options={LEGAL_NATURE_OPTIONS}
                  value={LEGAL_NATURE_OPTIONS.find(
                    (o) => o.value === field.value
                  )}
                  onChange={(option: { value: string }) =>
                    field.onChange(option.value)
                  }
                  errorMensage={errors.legalNature?.message as string}
                />
              )}
            />
          </div>

          <div>
            <Input
              variant="outline-orange"
              {...register('tradeName')}
              title="Nome Fantasia"
              errorMensage={errors.tradeName?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('name')}
              title="Razão social*"
              errorMensage={errors.name?.message as string}
            />
          </div>

          <div>
            <Input
              variant="outline-orange"
              {...register('email')}
              title="Email"
              type="email"
              errorMensage={errors.email?.message as string}
            />
          </div>

          <div>
            <Input
              variant="outline-orange"
              {...register('openningDate')}
              title="Data de Abertura"
              type="date"
              errorMensage={errors.openningDate?.message as string}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Regime de Tributação
            </label>
            <Controller
              name="taxRegime"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  {...field}
                  options={TAX_REGIME_OPTIONS}
                  onChange={(option: { value: string }) =>
                    field.onChange(option.value)
                  }
                  value={TAX_REGIME_OPTIONS.find(
                    (o) => o.value === field.value
                  )}
                  errorMensage={errors.taxRegime?.message as string}
                />
              )}
            />
          </div>

          {/* <div>
            <Input
              variant="outline-orange"
              {...register('companyRegistryNumber')}
              title="Número de Inscricação na Junta Comercial"
              type="number"
              errorMensage={errors.companyRegistryNumber?.message as string}
            />
          </div> */}

          {/* <div>
            <Input
              variant="outline-orange"
              {...register('createdOn')}
              title="Data de Criação"
              type="date"
              errorMensage={errors.createdOn?.message as string}
            />
          </div> */}
          {/* 
          <div>
            <Input
              variant="outline-orange"
              {...register('modifiedOn')}
              title="Data de Modificação"
              type="date"
              errorMensage={errors.modifiedOn?.message as string}
            />
          </div> */}
        </div>
      </div>
      <div>
        <h2 className="my-4 font-bold">Endereço da empresa</h2>
        <div className="grid grid-cols-2 gap-6 items-end">
          <div>
            <Input
              variant="outline-orange"
              {...register('postalCode')}
              title="CEP*"
              type="number"
              errorMensage={errors.postalCode?.message as string}
              onChange={onChangePostalCode}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('street')}
              disabled
              title="Rua"
              type="string"
              errorMensage={errors.street?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('number')}
              title="Número*"
              type="string"
              errorMensage={errors.number?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('additionalInformation')}
              title="Informação adicional"
              type="string"
              errorMensage={errors.additionalInformation?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('district')}
              title="Bairro"
              type="string"
              disabled
              errorMensage={errors.district?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('city')}
              title="Cidade"
              type="string"
              disabled
              errorMensage={errors.city?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('state')}
              title="Estado"
              type="string"
              disabled
              errorMensage={errors.district?.message as string}
            />
          </div>
          <div>
            <Input
              variant="outline-orange"
              {...register('code')}
              type="string"
              disabled
              hidden
              errorMensage={errors.district?.message as string}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <Button type="submit" className="px-4">
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};
const CompanyFiscalForm: FC<FormProps> = ({
  onSubmit,
  onBack,
  defaultValues,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(fiscalFormSchema),
    defaultValues,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-6 items-end">
        <div>
          <Input
            variant="outline-orange"
            {...register('municipalTaxNumber')}
            title="Incrição municipal *"
            errorMensage={errors.municipalTaxNumber?.message as string}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Regime Especial de Tributação
          </label>
          <Controller
            name="specialTaxRegime"
            control={control}
            render={({ field }) => (
              <SelectComponent
                {...field}
                onChange={(option: { value: string }) =>
                  field.onChange(option.value)
                }
                value={SPECIAL_TAX_REGIME_OPTIONS.find(
                  (o) => o.value === field.value
                )}
                options={SPECIAL_TAX_REGIME_OPTIONS}
                errorMensage={errors.specialTaxRegime?.message as string}
              />
            )}
          />
        </div>
        <div>
          <Input
            variant="outline-orange"
            {...register('regionalTaxNumber')}
            title="Número de Inscricação Estadual"
            errorMensage={errors.regionalTaxNumber?.message as string}
          />
        </div>
      </div>
      <div className="flex justify-center gap-2">
        <Button className="px-4" onClick={onBack}>
          Voltar
        </Button>
        <Button type="submit" className="px-4">
          Salvar e continuar
        </Button>
      </div>
    </form>
  );
};

const ServiceInvoiceCompanyConfig: React.FC = () => {
  const [activeForm, setActiveForm] = useState(0);
  const [companyData, setCompanyData] = useState({} as Record<string, any>);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const loadCompanyData = useCallback(() => {
    setLoadingCompanyData(true);
    ApiExpenses.getServiceInvoiceConfig()
      .then((serviceInvoiceConfigResponse) => {
        if (!serviceInvoiceConfigResponse?.data?.id) return;
        const {
          address: serviceInvoiceConfigAddress,
          ...serviceInvoiceConfig
        } = serviceInvoiceConfigResponse.data;
        setCompanyData({
          ...serviceInvoiceConfig,
          ...serviceInvoiceConfigAddress,
          city: serviceInvoiceConfigAddress.city?.name,
          code: serviceInvoiceConfig.city?.code,
          openningDate: serviceInvoiceConfig?.openningDate?.slice(0, 10),
        });
      })
      .catch((error) => {
        console.error(error);
        ToastNotify().notify({
          message: 'Não foi possível obter as configurações da empresa',
          type: 'Error',
        });
      })
      .finally(() => setLoadingCompanyData(false));
  }, []);
  useEffect(() => {
    loadCompanyData();
  }, []);
  const onBack = useCallback(() => setActiveForm((prev) => prev - 1), []);

  const onSubmitCallback = useCallback(
    (data: any) => {
      // eslint-disable-next-line no-use-before-define
      if (activeForm !== forms.length - 1 && activeForm !== 3) {
        setActiveForm((prev) => prev + 1);
        setCompanyData((prev) => ({ ...prev, ...data }));
        return;
      }

      setCompanyData((prev) => {
        const companyDataState = { ...prev, ...data };
        const {
          file: companyCertificateFile,
          password,
          city,
          district,
          postalCode,
          number,
          state,
          street,
          code,
          additionalInformation,
          federalTaxNumber,
          companyId,
          hasConfiguredCertificate,
          federalTaxDetermination,
          municipalTaxDetermination,
          certificate,
          type,
          country,
          environment,
          createdOn,
          modifiedOn,
          ...serviceInvoiceCompanyConfig
        } = companyDataState;
        const address = {
          city: {
            name: city,
            code,
          },
          additionalInformation: additionalInformation || null,
          district,
          postalCode,
          number,
          state,
          street,
          country: 'BRA',
        };
        serviceInvoiceCompanyConfig.address = address;
        serviceInvoiceCompanyConfig.federalTaxNumber = String(federalTaxNumber);
        ApiExpenses.createServiceInvoiceConfig(
          serviceInvoiceCompanyConfig
        ).then((response) => {
          if (typeof response === 'string') {
            ToastNotify().notify({
              message: 'Não foi possível salvar as configurações da empresa',
              type: 'Error',
            });
            return;
          }
          loadCompanyData();
          ApiExpenses.uploadServiceInvoiceCompanyCertificate(
            password,
            companyCertificateFile
          ).then((certificateResponse) => {
            if (typeof certificateResponse === 'string') {
              ToastNotify().notify({
                message: 'Não foi possível fazer o upload do certificado',
                type: 'Error',
              });
              return;
            }
            ToastNotify().notify({
              message: 'Certificado da empresa configurado com sucesso',
              type: 'Success',
            });
            setActiveForm((prevActiveForm) => ++prevActiveForm);
          });
        });
        return companyDataState;
      });
    },
    [activeForm]
  );
  const forms = useMemo(() => {
    const defaultTabs = [
      {
        name: 'Dados da empresa',
        Component: () => (
          <CompanyDataForm
            defaultValues={companyData}
            onSubmit={onSubmitCallback}
          />
        ),
      },
      {
        name: 'Configuração da nota',
        Component: () => (
          <CompanyFiscalForm
            defaultValues={companyData}
            onSubmit={onSubmitCallback}
            onBack={onBack}
          />
        ),
      },
      {
        name: 'Dados fiscais',
        Component: () => (
          <ServiceInvoiceForm
            defaultValues={companyData}
            onSubmit={onSubmitCallback}
            onBack={onBack}
          />
        ),
      },
      {
        name: 'Configuração de acesso',
        Component: () => (
          <ServiceInvoiceAccessData
            defaultValues={companyData}
            onSubmit={onSubmitCallback}
            onBack={onBack}
          />
        ),
      },
    ];
    if (companyData.id) {
      defaultTabs.push({
        name: 'Configuração de serviços',
        Component: () => <CompanyInvoiceServices onBack={onBack} />,
      });
    }
    return defaultTabs;
  }, [companyData, onSubmitCallback, onBack]);

  const { Component } = forms[activeForm];
  return (
    <div>
      <div className="flex gap-2 mt-4">
        {forms.map((form, index) => (
          <Button
            className="px-2"
            variant={index === activeForm ? 'primary' : 'gray'}
            disabled={index === activeForm}
            onClick={() => setActiveForm(index)}
          >
            {form.name}
          </Button>
        ))}
      </div>
      <hr className="w-full h-[1.5px] bg-primary mt-3 mb-4" />
      {loadingCompanyData ? <div>Carregando...</div> : <Component />}
    </div>
  );
};

export default ServiceInvoiceCompanyConfig;
