import React from 'react';
import { CalendarEdit } from '@styled-icons/fluentui-system-regular/CalendarEdit';
import { Bank } from '@styled-icons/remix-line/Bank';
import { UserSettings } from '@styled-icons/remix-line/UserSettings';
import { Pause } from '@styled-icons/fa-solid/Pause';
import { PlugDisconnected } from '@styled-icons/fluentui-system-regular/PlugDisconnected';
import { SlideSearch } from '@styled-icons/fluentui-system-regular/SlideSearch';
import { ArrowBack } from '@styled-icons/material-rounded/ArrowBack';
import { Image } from '@styled-icons/bootstrap/Image';
import { TypeBold } from '@styled-icons/bootstrap/TypeBold';
import { Checkbox } from '@styled-icons/remix-line/Checkbox';
import { CheckboxCircle } from '@styled-icons/remix-line/CheckboxCircle';
import { RadioButtonChecked } from '@styled-icons/material-rounded/RadioButtonChecked';
import { MenuAltLeft } from '@styled-icons/boxicons-regular/MenuAltLeft';
import { Menu } from '@styled-icons/boxicons-regular/Menu';
import { DocumentDuplicate } from '@styled-icons/heroicons-outline/DocumentDuplicate';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Triangle } from '@styled-icons/ionicons-sharp/Triangle';
import { Videos } from '@styled-icons/boxicons-solid/Videos';
import { ExclamationTriangleFill } from '@styled-icons/bootstrap/ExclamationTriangleFill';
import { TypeItalic } from '@styled-icons/bootstrap/TypeItalic';
import { TypeUnderline } from '@styled-icons/bootstrap/TypeUnderline';
import { FormatClear } from '@styled-icons/material-outlined/FormatClear';
import { HandStop } from '@styled-icons/zondicons/HandStop';
import { CardList } from '@styled-icons/bootstrap/CardList';
import { Tag } from '@styled-icons/boxicons-regular/Tag';
import { ArrowCounterclockwise } from '@styled-icons/bootstrap/ArrowCounterclockwise';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import { DocumentEdit } from '@styled-icons/fluentui-system-regular/DocumentEdit';
import { VideoPlayPause } from '@styled-icons/fluentui-system-regular/VideoPlayPause';
import { Play } from '@styled-icons/fluentui-system-regular/Play';
import { DesktopEdit } from '@styled-icons/fluentui-system-regular/DesktopEdit';
import { LineGraph } from '@styled-icons/entypo/LineGraph';
import { ArrowTrendingText } from '@styled-icons/fluentui-system-regular/ArrowTrendingText';
import { ArrowTrendingLines } from '@styled-icons/fluentui-system-filled/ArrowTrendingLines';
import { Printer } from '@styled-icons/boxicons-regular/Printer';
import { AlertTriangle } from '@styled-icons/evaicons-solid/AlertTriangle';
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline';
import { CurrencyDollar } from '@styled-icons/bootstrap/CurrencyDollar';
import { Tiktok } from '@styled-icons/simple-icons/Tiktok';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Facebook } from '@styled-icons/fa-brands/Facebook';
import { MessageSquareEdit } from '@styled-icons/boxicons-regular/MessageSquareEdit';
import { DocumentTextToolbox } from '@styled-icons/fluentui-system-regular/DocumentTextToolbox';
import { FileEarmarkPdf } from '@styled-icons/bootstrap/FileEarmarkPdf';
import { AddToQueue } from '@styled-icons/boxicons-regular/AddToQueue';
import { MoneyDollarCircle } from '@styled-icons/remix-line/MoneyDollarCircle';
import { Lightbulb } from '@styled-icons/bootstrap/Lightbulb';
import { FileSignature } from '@styled-icons/fa-solid/FileSignature';
import { BuildingHouse } from '@styled-icons/boxicons-regular/BuildingHouse';
import { Admin } from '@styled-icons/remix-fill/Admin';
import { MoneyCalculator } from '@styled-icons/fluentui-system-regular/MoneyCalculator';
import { CircularGraph } from '@styled-icons/entypo/CircularGraph';
import { TextBulletListSquareEdit } from '@styled-icons/fluentui-system-regular/TextBulletListSquareEdit';
import { Pets } from '@styled-icons/material/Pets';
import { Payments } from '@styled-icons/material-outlined/Payments';
import { CategoryAlt } from '@styled-icons/boxicons-regular/CategoryAlt';
import { Color } from '@styled-icons/fluentui-system-regular/Color';
import { PersonWorkspace } from '@styled-icons/bootstrap/PersonWorkspace';
import { Store } from '@styled-icons/boxicons-regular/Store';
import { FlowTree } from '@styled-icons/entypo/FlowTree';
import { AlertCircle } from '@styled-icons/ionicons-outline/AlertCircle';
import { Import } from '@styled-icons/boxicons-regular/Import';
import { LinkSlash } from '@styled-icons/fa-solid/LinkSlash';
import { DocumentText } from '@styled-icons/fluentui-system-regular/DocumentText';
import { FileExcel } from '@styled-icons/fa-regular/FileExcel';
import { Link45deg } from '@styled-icons/bootstrap/Link45deg';
import { BotAdd } from '@styled-icons/fluentui-system-regular/BotAdd';
import { BuildingMultiple } from '@styled-icons/fluentui-system-regular/BuildingMultiple';
import { MenuAdd } from '@styled-icons/remix-line/MenuAdd';
import { Settings } from '@styled-icons/ionicons-outline/Settings';
import { Filter } from '@styled-icons/bootstrap/Filter';
import { CheckmarkCircle2Outline } from '@styled-icons/evaicons-outline/CheckmarkCircle2Outline';
import { Clock } from '@styled-icons/bootstrap/Clock';
import { MinusOutline } from '@styled-icons/evaicons-outline/MinusOutline';
import { Add } from '@styled-icons/fluentui-system-filled/Add';
import { EditAlt } from '@styled-icons/boxicons-regular/EditAlt';
import { CartFlatbedSuitcase } from '@styled-icons/fa-solid/CartFlatbedSuitcase';
import { Whatsapp } from '@styled-icons/icomoon/Whatsapp';
import { Calendar } from '@styled-icons/boxicons-regular/Calendar';
import { Funnel } from '@styled-icons/bootstrap/Funnel';
import { MagnifyingGlass } from '@styled-icons/entypo/MagnifyingGlass';
import { Download } from '@styled-icons/ionicons-outline/Download';
import { Home } from '@styled-icons/octicons/Home';
import { Smile } from '@styled-icons/boxicons-regular/Smile';
import { DocumentBulletList } from '@styled-icons/fluentui-system-filled/DocumentBulletList';
import { User } from '@styled-icons/heroicons-outline/User';
import { Inboxes } from '@styled-icons/bootstrap/Inboxes';
import { Category } from '@styled-icons/boxicons-regular/Category';
import { Menu2Outline } from '@styled-icons/evaicons-outline/Menu2Outline';
import { EmojiTransportation } from '@styled-icons/material/EmojiTransportation';
import { Share2 } from '@styled-icons/feather/Share2';
import { Buildings } from '@styled-icons/boxicons-regular/Buildings';
import { GraphUp } from '@styled-icons/bootstrap/GraphUp';
import { Profile } from '@styled-icons/remix-line/Profile';
import { HandThumbsUp } from '@styled-icons/bootstrap/HandThumbsUp';
import { HandThumbsDown } from '@styled-icons/bootstrap/HandThumbsDown';
import { Contacts } from '@styled-icons/material-outlined/Contacts';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { BroadActivityFeed } from '@styled-icons/fluentui-system-regular/BroadActivityFeed';
import { DataFunnel } from '@styled-icons/fluentui-system-regular/DataFunnel';
import { Groups } from '@styled-icons/material-outlined/Groups';
import { FileEarmarkBarGraph } from '@styled-icons/bootstrap/FileEarmarkBarGraph';
import { PeopleTeam } from '@styled-icons/fluentui-system-regular/PeopleTeam';
// @styled-icons/bootstrap/PeopleFill
import { Person } from '@styled-icons/fluentui-system-regular/Person';
import { FormNew } from '@styled-icons/fluentui-system-regular/FormNew';
import { ChatHelp } from '@styled-icons/fluentui-system-regular/ChatHelp';
import { Handshake } from '@styled-icons/fa-regular/Handshake';
import { SignOut } from '@styled-icons/fluentui-system-regular/SignOut';
import { Sell } from '@styled-icons/material-outlined/Sell';
import { Segment } from '@styled-icons/material-sharp/Segment';
import { Group } from '@styled-icons/fluentui-system-regular/Group';
import { Block } from '@styled-icons/material-sharp/Block';
import { Brush } from '@styled-icons/ionicons-solid/Brush';
import { Plus } from '@styled-icons/boxicons-regular/Plus';
import { AddPhotoAlternate } from '@styled-icons/material-outlined/AddPhotoAlternate';
import { PhotoCamera } from '@styled-icons/material/PhotoCamera';
import { Pen } from '@styled-icons/bootstrap/Pen';
import { Send } from '@styled-icons/bootstrap/Send';
import { PlugConnected } from '@styled-icons/fluentui-system-filled/PlugConnected';
import { WhatsappSquare } from '@styled-icons/fa-brands/WhatsappSquare';
import { StopCircle } from '@styled-icons/bootstrap/StopCircle';
import { QrCode } from '@styled-icons/bootstrap/QrCode';
import { BoxMultipleArrowRight } from '@styled-icons/fluentui-system-filled/BoxMultipleArrowRight';
import { Database } from '@styled-icons/entypo/Database';
import { ImageAdd } from '@styled-icons/remix-fill/ImageAdd';
import { FaFileInvoiceDollar, FaFileSignature } from 'react-icons/fa';
import { Sliders } from '@styled-icons/bootstrap/Sliders';
import { Clone } from '@styled-icons/fa-regular/Clone';
import { PiSignatureThin } from 'react-icons/pi';
import { File } from '@styled-icons/octicons/File';
import { Box } from '@styled-icons/bootstrap/Box';
import { Pix } from '@styled-icons/fa-brands/Pix';
import { DocumentTextExtract } from '@styled-icons/fluentui-system-regular/DocumentTextExtract';
import { CoinStack } from '@styled-icons/boxicons-solid/CoinStack';
import { CreditCard } from '@styled-icons/bootstrap/CreditCard';
import { Barcode } from '@styled-icons/icomoon/Barcode';
import { Copy } from '@styled-icons/ionicons-outline/Copy';
import { Receipt } from '@styled-icons/bootstrap/Receipt';
import { SkipForwardTab } from '@styled-icons/fluentui-system-filled/SkipForwardTab';
import {
  ArrowIosDownward,
  ArrowIosUpward,
  ArrowIosForward,
} from '@styled-icons/evaicons-solid';
import { PlaneTakeOff } from '@styled-icons/boxicons-solid/PlaneTakeOff';
import { PlaneLand } from '@styled-icons/boxicons-solid/PlaneLand';
import { KingBed } from '@styled-icons/material/KingBed';
import { Ticket } from '@styled-icons/zondicons/Ticket';
import { AirplanemodeActive } from '@styled-icons/material/AirplanemodeActive';
import { AirplaneTicket } from '@styled-icons/material-outlined/AirplaneTicket';

const IconDictionary: React.FC<{
  name: string;
  size?: number;
  className?: string;
  color?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  title?: string;
}> = ({
  name,
  size,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  color,
  title,
}) => {
  const sizeIcon = size ?? 18;
  switch (name) {
    case 'dashboard-admin':
    case 'dashboard':
    case 'Resumo':
      return (
        <Home
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'category-admin':
    case 'Categorias':
      return (
        <Category
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'menu-admin':
    case 'Menus':
      return (
        <Menu2Outline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'profile-admin':
    case 'Perfis':
      return (
        <Profile
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'product-admin':
    case 'Produtos':
      return (
        <Inboxes
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'financeiro':
      return (
        <GraphUp
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'leads':
    case 'Parceiros':
      return (
        <PeopleTeam
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'minha-conta':
      return (
        <Person
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'central-de-ajuda':
      return (
        <ChatHelp
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'desconectar':
      return (
        <SignOut
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Fornecedores':
    case 'provider':
      return (
        <Groups
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Clientes':
    case 'client':
      return (
        <Handshake
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'user-admin':
    case 'Usuários Admin':
    case 'Usuários Administradores':
    case 'user':
    case 'Usuários':
      return (
        <User
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Passageiros':
    case 'pax':
      return (
        <EmojiTransportation
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Cadastros':
    case 'Adicionar formulario':
      return (
        <FormNew
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'companie-admin':
    case 'Franqueadora e Franquias':
      return (
        <Buildings
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Funis de vendas':
    case 'Funil de Vendas':
    case 'Funil de vendas':
    case 'funnel':
      return (
        <DataFunnel
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Tarefas':
    case 'Tipos de Tarefas':
    case 'activities':
    case 'activities-type':
      return (
        <BroadActivityFeed
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Canal de vendas':
    case 'sales-channel':
      return (
        <Sell
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Respostas de ganho':
    case 'responses-win':
      return (
        <HandThumbsUp
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Respostas de perda':
    case 'responses-lose':
      return (
        <HandThumbsDown
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Segmentos':
    case 'segment':
      return (
        <Segment
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Contatos':
    case 'contacts':
      return (
        <Contacts
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'imports-opportunities':
    case 'Oportunidades':
    case 'reports-opportunity':
    case 'opportunity':
    case 'Relatório Oportunidades':
      return (
        <CartFlatbedSuitcase
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Transferir oportunidades':
    case 'transfer-opportunity':
      return (
        <Share2
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Relatórios':
      return (
        <FileEarmarkBarGraph
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Vendas antigas':
    case 'purchase-olds':
      return (
        <Sell
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'group-admin':
    case 'Grupos':
      return (
        <Group
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Funil':
      return (
        <Funnel
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Lupa':
      return (
        <MagnifyingGlass
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Calendario':
      return (
        <Calendar
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Download':
      return (
        <Download
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Whatsapp':
    case 'Cadastrar Instância':
      return (
        <Whatsapp
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Editar':
      return (
        <EditAlt
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Adicionar':
      return (
        <Add
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Hide':
      return (
        <MinusOutline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Excluir':
      return (
        <CloseOutline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Relogio':
      return (
        <Clock
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Circulo Confirmar':
      return (
        <CheckmarkCircle2Outline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Filtro':
      return (
        <Filter
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Configurações':
      return (
        <Settings
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Menus por perfil':
    case 'menus-profile':
      return (
        <MenuAdd
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Acesso a empresas':
    case 'access-companie-group':
      return (
        <BuildingMultiple
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'input-automations':
    case 'Automações de entrada':
      return (
        <BotAdd
          size={sizeIcon + 1}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Link':
      return (
        <Link45deg
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Excel':
      return (
        <FileExcel
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Documentação':
      return (
        <DocumentText
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Link invalido':
      return (
        <LinkSlash
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Importações':
      return (
        <Import
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'AlertCircle':
      return (
        <AlertCircle
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Módulos':
      return (
        <FlowTree
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Franquias':
      return (
        <Store
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'RH':
      return (
        <PersonWorkspace
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Cores':
      return (
        <Color
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Tipo de Pet':
      return (
        <Pets
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Raças':
      return (
        <CategoryAlt
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Contas a pagar e receber':
      return (
        <Payments
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Plano de Contas':
      return (
        <TextBulletListSquareEdit
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Relatório Balancete':
      return (
        <CircularGraph
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Relatório Fluxo de Caixa':
      return (
        <MoneyCalculator
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Relatório Contas a pagar e receber':
      return (
        <Payments
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Permissões por perfil':
      return (
        <Admin
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Franquias e Franqueadora':
      return (
        <BuildingHouse
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Contrato':
    case 'Contratos':
    case 'Personalizar Contrato':
      return (
        <FileSignature
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Prospecção':
      return (
        <Lightbulb
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Financeiro':
      return (
        <MoneyDollarCircle
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Adicionar nivel':
      return (
        <AddToQueue
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'PDF':
      return (
        <FileEarmarkPdf
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Serviços':
      return (
        <DocumentTextToolbox
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Personalizar':
    case 'Mensagens Padrão':
    case 'Mensagens de Whatsapp':
      return (
        <MessageSquareEdit
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Facebook':
      return (
        <Facebook
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Instagram':
      return (
        <Instagram
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Tiktok':
      return (
        <Tiktok
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Money':
      return (
        <CurrencyDollar
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Cancelar':
      return (
        <Block
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Email':
      return (
        <EmailOutline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'AlertTriangle':
      return (
        <AlertTriangle
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Print':
      return (
        <Printer
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'bi-contracts':
      return (
        <ArrowTrendingText
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'bi-financial':
      return (
        <LineGraph
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'bi-funnel':
      return (
        <ArrowTrendingLines
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'brush':
      return (
        <Brush
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'plus':
      return (
        <Plus
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'add-photo':
      return (
        <AddPhotoAlternate
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'photo-camera':
      return (
        <PhotoCamera
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Personalizar Layout':
      return (
        <DesktopEdit
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'reload':
      return (
        <VideoPlayPause
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Personalizar Proposta':
      return (
        <Pen
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Propostas':
      return (
        <DocumentEdit
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Reset':
      return (
        <Reset
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Reabrir':
      return (
        <ArrowCounterclockwise
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Planos de Whatsapp':
      return (
        <Whatsapp
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Cartao lista':
      return (
        <CardList
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Negrito':
      return (
        <TypeBold
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Itálico':
      return (
        <TypeItalic
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Sublinhado':
      return (
        <TypeUnderline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Limpar Formatação':
      return (
        <FormatClear
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'resposta_curta':
      return (
        <MenuAltLeft
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'radio':
      return (
        <RadioButtonChecked
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'paragrafo':
      return (
        <Menu
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Checkbox':
      return (
        <Checkbox
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Lixeira':
      return (
        <Trash
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Duplicar':
      return (
        <DocumentDuplicate
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'CheckboxCircle':
      return (
        <CheckboxCircle
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Formulário de Pesquisa':
      return (
        <SlideSearch
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Seta voltar':
      return (
        <ArrowBack
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Desconectado':
      return (
        <PlugDisconnected
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Tags':
      return (
        <Tag
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Triangle':
      return (
        <Triangle
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Smile':
      return (
        <Smile
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'DocumentBulletList':
      return (
        <DocumentBulletList
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Image':
      return (
        <Image
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Videos':
      return (
        <Videos
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Send':
      return (
        <Send
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'PlugConnected':
      return (
        <PlugConnected
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'WhatsappSquare':
      return (
        <WhatsappSquare
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );

    case 'TrashSolid':
      return (
        <Trash
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );

    case 'StopCircle':
      return (
        <StopCircle
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );

    case 'QrCode':
      return (
        <QrCode
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'BoxMultipleArrowRight':
    case 'Envio em Massa':
      return (
        <BoxMultipleArrowRight
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Database':
      return (
        <Database
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'ExclamationTriangleFill':
      return (
        <ExclamationTriangleFill
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Imagem':
      return (
        <ImageAdd
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Iniciar':
      return (
        <Play
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Parar':
      return (
        <HandStop
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Pausar':
      return (
        <Pause
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Planos Assinatura Digital':
    case 'Crédito Assinatura Digital':
      return (
        <FaFileSignature
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Parâmetros':
      return (
        <Sliders
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );

    case 'Clonar':
      return (
        <Clone
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          color={color}
        />
      );
    case 'Crédito assinatura digital':
      return (
        <FaFileSignature
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Assinar contrato':
      return (
        <PiSignatureThin
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'email':
      return (
        <EmailOutline
          size={sizeIcon}
          className={className}
          onClick={onClick}
          title={title}
          color={color}
        />
      );

    case 'Planos Limite de Usuários':
      return (
        <UserSettings
          size={sizeIcon}
          className={className}
          onClick={onClick}
          title={title}
          color={color}
        />
      );
    case 'Calendario Editar':
      return (
        <CalendarEdit
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Arquivo':
      return (
        <File
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Conta no Banco':
    case 'Contas no Banco':
      return (
        <Box
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Pix':
    case 'Pagamento via PIX':
      return (
        <Pix
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Extrato Bancário':
      return (
        <DocumentTextExtract
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Coin':
      return (
        <CoinStack
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Automações':
    case 'Automação de envio de Whatsapp':
      return (
        <PeopleTeam
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );
    case 'Recibo':
      return (
        <Receipt
          size={sizeIcon}
          title={title}
          className={className}
          onClick={onClick}
          color={color}
        />
      );

    case 'Banco':
      return (
        <Bank
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Copiar':
      return (
        <Copy
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Cartão de Crédito':
      return (
        <CreditCard
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Boleto':
      return (
        <Barcode
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    case 'Relatório Nota fiscal de serviço':
    case 'Crédito de nota fiscal':
    case 'Crédito de notas fiscais':
    case 'Configuração de nota fiscal':
    case 'Nota Fiscal Serviço':
      return (
        <FaFileInvoiceDollar
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'SkipForwardTab':
      return (
        <SkipForwardTab
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Seta Abaixo':
      return (
        <ArrowIosDownward
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Seta Acima':
      return (
        <ArrowIosUpward
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Seta Direita':
      return (
        <ArrowIosForward
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Avião Decolar':
      return (
        <PlaneTakeOff
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Avião Aterrizar':
      return (
        <PlaneLand
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Avião':
      return (
        <AirplanemodeActive
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Cama':
      return (
        <KingBed
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Ticket':
      return (
        <Ticket
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );

    case 'Ticket Avião':
      return (
        <AirplaneTicket
          size={sizeIcon}
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          title={title}
          color={color}
        />
      );
    default:
      return <> </>;
  }
};

export default IconDictionary;
