import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Address,
  Attachments,
  Button,
  Modal,
  SocialMedia,
} from '../../../../../components';
import { ToastNotify } from '../../../../../components/Toast/toast';
import { createUpdateformProps } from '../../types';
import { ApiCompany } from '../../api';
import { useFetchCompanyAttachments } from '../../hooks';
import { CreateUpdateBranch, Matrix } from '../../utils';

import GeneralData from './components/generalData';
import CreateUser from './components/createUser';
import ReleaseMenu from './components/releaseMenu';
import Branch from './components/branch';
import Payments from './components/payment';
import Configuration from './components/configuration';
import { useFetchUsersCompanie } from '../../../../users/hooks';
import ImportClients from './components/importClients';

const Register: React.FC<createUpdateformProps> = ({
  show,
  setShow,
  setCompanyDefault,
  registerPage,
  setRegisterPage,
  reloadTable,
  adminUserId,
  companyDefault,
  GroupList,
  MenuList,
  ProfileList,
  action,
  hasFranchisor,
  franchisorCompanies,
}) => {
  const { notify } = ToastNotify();
  const [key, setKey] = useState(`attachments`);
  const [companyId, setCompanyId] = useState(-1);
  const [keyUser, setKeyUser] = useState('');
  const {
    UsersList,
    isLoading: isLoadingUsers,
    setIsLoading,
  } = useFetchUsersCompanie(keyUser, companyDefault?.id ?? companyId);

  const { attachmentsList, isLoading } = useFetchCompanyAttachments(
    key,
    companyDefault?.id ?? companyId
  );

  const header =
    action === 'update'
      ? companyDefault?.is_franchise_matriz
        ? Matrix
        : CreateUpdateBranch
      : CreateUpdateBranch;

  const nextPage = () => {
    if (registerPage.page < header.length - 1) {
      setRegisterPage({
        title: header[registerPage.page + 1].title,
        page: registerPage.page + 1,
      });
    } else {
      reloadTable(`${Math.random()} 'create'`);
      setShow(false);
    }
  };

  useEffect(() => {
    setKey(`${Math.random()} files`);
    setKeyUser(`${Math.random()} files`);
  }, [show]);
  return (
    <Modal
      minHeight="min-h-[730px]"
      size="6xlarge"
      title={companyDefault ? 'Atualizar Empresa' : 'Cadastrar Empresa'}
      isOpen={show}
      setIsOpen={() => {
        setShow(false);
        reloadTable(`${Math.random()} 'create'`);
      }}
    >
      <div className="flex flex-col">
        <div className="grid grid-cols-2 md:flex flex-wrap lg:flex-nowrap gap-2 mt-9">
          {header.map((item) => (
            <Button
              variant={
                registerPage.page === item.page ? 'primary-strong' : 'gray'
              }
              actionType="button-full-width"
              key={item.id}
              className={classNames([
                'font-medium',
                'md:w-32 md:min-w-fit md:px-2',
                companyDefault !== null ? 'cursor-pointer' : 'cursor-default',
              ])}
              onClick={() => companyDefault !== null && setRegisterPage(item)}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <hr className="w-full h-[1.5px] bg-primary mt-3" />
        {registerPage.page === 0 && (
          <GeneralData
            hasFranchisor={hasFranchisor}
            setId={setCompanyDefault}
            notify={notify}
            nextPage={(e) => {
              if (e) setCompanyDefault({ ...companyDefault, ...e });
              nextPage();
            }}
            defaultValue={companyDefault}
            id={adminUserId}
          />
        )}
        {registerPage.page === 1 && (
          <Address
            apiFunction={async (values, id) => {
              const res = await ApiCompany.updateAddress(values, id);
              if (res.id) {
                setCompanyDefault({ ...res });
              }
              return res;
            }}
            defaultValue={
              companyDefault && {
                address: companyDefault.address ?? '',
                city: companyDefault.city ?? '',
                complement: companyDefault.complement,
                province: companyDefault.province ?? '',
                state: companyDefault.state ?? '',
                zipcode: companyDefault.zipcode ?? '',
              }
            }
            id={companyDefault?.id ?? companyId}
            nextPage={nextPage}
          />
        )}
        {registerPage.page === 2 && (
          <Attachments
            AttachmentsList={attachmentsList}
            apiFunction={ApiCompany.createCompanyAttachments}
            deleteApiFunction={ApiCompany.deleteAttachments}
            id={companyDefault?.id ?? companyId}
            keyFile="companie_id"
            nextPage={nextPage}
            updateTable={setKey}
            isLoadingTable={isLoading}
          />
        )}
        {registerPage.page === 3 && (
          <SocialMedia
            apiFunction={async (values, id) => {
              const res = await ApiCompany.updateSocialMedia(values, id);
              if (res.id) {
                setCompanyDefault(res);
              }
              return res;
            }}
            id={companyDefault?.id ?? companyId}
            nextPage={nextPage}
            setId={setCompanyId}
            fields={[
              'homepage',
              'other_homepage',
              'facebook',
              'instagram',
              'twitter',
              'obs',
            ]}
            defaultValues={{
              facebook: companyDefault?.facebook ?? '',
              instagram: companyDefault?.instagram ?? '',
              homepage: companyDefault?.homepage ?? '',
              obs: companyDefault?.obs ?? '',
            }}
          />
        )}
        {registerPage.page === 4 &&
        (action === 'create' ||
          (companyDefault && !companyDefault.is_franchise_matriz)) ? (
          <CreateUser
            UsersList={UsersList}
            isLoading={isLoadingUsers}
            setIsLoading={setIsLoading}
            setKey={setKeyUser}
            isFranchise={companyDefault?.is_franchise}
            setId={setCompanyDefault}
            notify={notify}
            nextPage={nextPage}
            defaultValue={companyDefault}
            id={companyDefault?.id ?? companyId}
          />
        ) : (
          registerPage.page === 4 &&
          action === 'update' &&
          companyDefault?.is_franchise_matriz && (
            <ReleaseMenu
              isFranchisor={companyDefault?.is_franchisor}
              GroupList={GroupList}
              MenuList={MenuList}
              ProfileList={ProfileList}
              nextPage={nextPage}
              companyId={companyDefault?.id ?? companyId}
            />
          )
        )}
        {registerPage.page === 5 &&
        (action === 'create' ||
          (companyDefault && !companyDefault.is_franchise_matriz)) ? (
          <ReleaseMenu
            isFranchisor={companyDefault?.is_franchisor ?? false}
            GroupList={GroupList}
            MenuList={MenuList}
            ProfileList={ProfileList}
            nextPage={nextPage}
            companyId={companyDefault?.id ?? companyId}
          />
        ) : (
          registerPage.page === 5 &&
          action === 'update' &&
          companyDefault?.is_franchise_matriz && (
            <CreateUser
              UsersList={UsersList}
              isLoading={isLoadingUsers}
              setIsLoading={setIsLoading}
              setKey={setKeyUser}
              isFranchise={companyDefault.is_franchise}
              setId={setCompanyDefault}
              notify={notify}
              nextPage={nextPage}
              defaultValue={companyDefault}
              id={companyDefault?.id ?? companyId}
            />
          )
        )}

        {registerPage.page === 6 && (
          <Payments
            setId={setCompanyDefault}
            notify={notify}
            nextPage={nextPage}
            defaultValue={companyDefault}
            id={companyDefault?.id ?? companyId}
          />
        )}

        {registerPage.page === 7 && (
          <Configuration
            UsersList={UsersList}
            isLoadingUser={isLoadingUsers}
            setIsLoadingUser={setIsLoading}
            setKey={reloadTable}
            setId={setCompanyDefault}
            notify={notify}
            nextPage={(e) => {
              if (e) setCompanyDefault({ ...companyDefault, ...e });
              nextPage();
            }}
            defaultValue={companyDefault}
            id={adminUserId}
            franchisorCompanies={franchisorCompanies}
          />
        )}

        {registerPage.page === 8 && companyDefault?.is_franchise_matriz && (
          <Branch
            nextPage={nextPage}
            brachCompanies={companyDefault.other_companie}
          />
        )}

        {((registerPage.page === 9 && companyDefault?.is_franchise_matriz) ||
          (registerPage.page === 8 && !companyDefault?.is_franchise_matriz)) &&
          action === 'update' && (
            <ImportClients
              mode="admin"
              id={
                companyDefault &&
                companyDefault.companie_user &&
                companyDefault.companie_user.length > 0
                  ? companyDefault.companie_user[0].id
                  : -1
              }
            />
          )}

        <div className="flex w-full gap-2 justify-center mt-9">
          {header.map((item) =>
            item.page === registerPage.page ? (
              <div className="h-3 w-3 rounded-full bg-primary" key={item.id}>
                {' '}
              </div>
            ) : (
              <div className="h-3 w-3 rounded-full bg-gray-400" key={item.id}>
                {' '}
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Register;
